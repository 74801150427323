"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deck = void 0;
const consts_1 = require("./lib/consts");
const ACE = "A";
class Deck {
    startRank;
    endRank;
    cards;
    constructor(start = "2", end = ACE) {
        const startRankIsAce = start === ACE;
        if (startRankIsAce) {
            start = "2";
        }
        this.startRank = consts_1.RankValuesMap[start];
        this.endRank = consts_1.RankValuesMap[end];
        this.cards = [];
        const suits = Object.keys(consts_1.SuitsMap);
        const ranks = Object.keys(consts_1.RankValuesMap).filter((rank) => consts_1.RankValuesMap[rank] >= this.startRank && consts_1.RankValuesMap[rank] <= this.endRank);
        if (startRankIsAce && end !== ACE) {
            ranks.push(ACE);
        }
        for (const suit of suits) {
            for (const rank of ranks) {
                this.cards.push(`${rank}${suit}`);
            }
        }
    }
    shuffle() {
        for (let i = this.cards.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * i);
            const temp = this.cards[i];
            this.cards[i] = this.cards[j];
            this.cards[j] = temp;
        }
    }
    draw(count) {
        return this.cards.splice(0, count);
    }
    returnCardToDeck(card) {
        this.cards.push(card);
    }
    removeFromDeck(card) {
        const index = this.cards.indexOf(card);
        if (index !== -1) {
            this.cards.splice(index, 1);
        }
    }
}
exports.Deck = Deck;
