"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hand = void 0;
function Hand(cards) {
    const ranksMap = {};
    const suitsMap = {};
    const strengthCode = "0.0.0";
    const strengthName = "";
    for (const card of cards) {
        const [rank, suit] = card;
        ranksMap[rank] = ranksMap[rank] || 0;
        ranksMap[rank]++;
        suitsMap[suit] = suitsMap[suit] || 0;
        suitsMap[suit]++;
    }
    return {
        cards,
        ranksMap,
        suitsMap,
        strengthCode,
        strengthName,
    };
}
exports.Hand = Hand;
