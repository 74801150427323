import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import About from "./components/About";
import Home from "./components/Home";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Header from "./components/Header";
import Felt from "./components/Felt";
import Footer from "./components/Footer";

import "./App.css";
import Music from "./components/Music";

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  components: {
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            color: "#378de9", // Change this to your desired color
          },
        },
      },
    },
  },
});

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <Header />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </Router>
        <Footer />
        <Music />
      </ChakraProvider>
    </div>
  );
}

export default App;
