import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "modalStore",
  initialState: {
    isModalOpen: false,
    playerIndex: 0,
    cardIndex: 0,
  },
  reducers: {
    openModal: (state, action) => {
      const { type, playerIndex, cardIndex } = action.payload;
      return { ...state, isModalOpen: true, type, playerIndex, cardIndex };
    },
    closeModal: (state) => {
      return { ...state, isModalOpen: false };
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
