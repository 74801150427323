import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GameType } from "poker-hand-strength-calculator";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { Icon, CircularProgress } from "@chakra-ui/react";
import { FaPlus, FaPlay, FaPause, FaArrowLeft, FaArrowRight, FaCog, FaInfo } from "react-icons/fa";

import { actions as pokerStoreActions } from "../store/slices/pokerStoreSlice";
import { ERounds, CALCULATE_ODDS_DELAY } from "../lib/consts";
import { useKeyPress } from "../hooks/useKeyPress";

export default function Header() {
  const dispatch = useDispatch();
  const { deck, game } = useSelector((state) => state.pokerStore);

  const btnResetRef = useRef(null);
  const selectPlayersRef = useRef(null);
  const rangeFromRef = useRef(null);
  const rangeToRef = useRef(null);

  const startRank = rangeFromRef.current?.value;
  const endRank = rangeToRef.current?.value;
  const playerCount = parseInt(selectPlayersRef.current?.value);

  const currentRoundRef = useRef(ERounds.GAME_START);
  const autoDealSpeedRef = useRef(2000);
  const btnArrowLeftRef = useRef(null);
  const btnArrowRightRef = useRef(null);
  const btnPlayRef = useRef(null);

  const [autoDealingTimer, setAutoDealingTimer] = useState(0);
  const [autoDealingProgressValue, setAutoDealingProgressValue] = useState(0);
  const [autoDealingProgressTimer, setAutoDealingProgressTimer] = useState(0);

  const [tabIndex, setTabIndex] = useState(3);

  useKeyPress({
    p: (e) => playClick(e),
    P: (e) => playClick(e),
    ArrowLeft: (e) => btnArrowLeftRef.current.click(),
    ArrowRight: (e) => btnArrowRightRef.current.click(),
    "+": (e) => btnResetRef.current.click(),
  });

  function playClick(e) {
    btnPlayRef.current.click();
  }

  useEffect(() => {
    dispatch(pokerStoreActions.resetTable({ startRank, endRank, playerCount, gameType: GameType.TEXAS_HOLDEM }));
    setTabIndex(0);

    // dispatch(pokerStoreActions.omahaTestCase({}));
  }, [playerCount]);

  const onReset = () => {
    const startRank = rangeFromRef.current?.value;
    const endRank = rangeToRef.current?.value;
    const playerCount = parseInt(selectPlayersRef.current?.value);
    dispatch(pokerStoreActions.resetTable({ startRank, endRank, playerCount, gameType: game.name }));
    dispatch(pokerStoreActions.setCurrentRound({ currentRound: ERounds.GAME_START }));
    currentRoundRef.current = ERounds.GAME_START;
    autoDeal(false);
  };

  function handleResetDisabled() {
    const startRank = rangeFromRef.current.value;
    const endRank = rangeToRef.current.value;
    let result = false;
    const ACE = "A";
    const ranksValue = { 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, T: 10, J: 11, Q: 12, K: 13, A: 14 };
    if (startRank === ACE && endRank === ACE) {
      result = true;
    } else if (ranksValue[startRank] - ranksValue[endRank] >= 0 && startRank !== ACE) {
      result = true;
    }
    btnResetRef.current.disabled = result;
  }

  const onAutoDeal = () => {
    // toggle auto deal
    if (autoDealingTimer) {
      setAutoDealingTimer(null);
      autoDeal(false);
    } else {
      autoDeal(true);
    }
  };

  function autoDeal(keepDealing) {
    const delay = Math.max(autoDealSpeedRef.current.value, currentRoundRef.current * 1000);
    const increment = parseInt(Math.ceil((100 / delay) * 1000));

    clearTimeout(autoDealingProgressTimer);

    if (!keepDealing) {
      clearTimeout(autoDealingTimer);
      setAutoDealingProgressValue(0);
      return;
    }

    if (currentRoundRef.current > game.drawSequence.length) {
      setTimeout(() => {
        btnResetRef.current.click();
        autoDeal(true);
      }, delay + 1000);
    }
    const timer = setTimeout(() => {
      btnArrowRightRef.current.click();

      autoDeal(true);
    }, delay + 1000);
    setAutoDealingTimer(timer);

    const progressTimer = setInterval(() => {
      setAutoDealingProgressValue((prev) => {
        const value = prev + increment;
        return value;
      });
    }, 1000);
    setAutoDealingProgressTimer(progressTimer);
  }

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const getGameName = () => {
    const map = {
      [GameType.TEXAS_HOLDEM]: "Texas Holdem",
      [GameType.OMAHA]: "Omaha",
      [GameType.FIVE_CARD_DRAW]: "5 Card Draw",
      [GameType.SEVEN_CARD_STUD]: "7 Card Stud",
    };
    return map[game.name];
  };

  return (
    <div className="header">
      <Grid>
        <GridItem>
          <a href="/">
            <img className="logo" src="/logo.png" alt="logo" title="logo" />
          </a>
        </GridItem>
        <GridItem>Ads here</GridItem>
        <GridItem colSpan={2}>
          <Tabs defaultIndex={tabIndex} index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              <Tab
                title="Select Texas Holdem"
                onClick={() => {
                  if (game.name !== GameType.TEXAS_HOLDEM) {
                    dispatch(
                      pokerStoreActions.resetTable({ startRank, endRank, playerCount, gameType: GameType.TEXAS_HOLDEM })
                    );
                  }
                  autoDeal(false);
                  setTabIndex(0);
                }}
              >
                Holdem
              </Tab>
              <Tab
                title="Select Omaha"
                onClick={() => {
                  if (game.name !== GameType.OMAHA) {
                    dispatch(
                      pokerStoreActions.resetTable({ startRank, endRank, playerCount, gameType: GameType.OMAHA })
                    );
                  }
                  autoDeal(false);
                  setTabIndex(1);
                }}
              >
                Omaha
              </Tab>
              <Tab
                title="Select Five Card Stud"
                onClick={() => {
                  if (game.name !== GameType.FIVE_CARD_DRAW) {
                    dispatch(
                      pokerStoreActions.resetTable({
                        startRank,
                        endRank,
                        playerCount,
                        gameType: GameType.FIVE_CARD_DRAW,
                      })
                    );
                  }
                  autoDeal(false);
                  setTabIndex(2);
                }}
              >
                5-CD
              </Tab>{" "}
              <Tab
                title="Select Seven Card Stud"
                onClick={() => {
                  if (game.name !== GameType.SEVEN_CARD_STUD) {
                    dispatch(
                      pokerStoreActions.resetTable({
                        startRank,
                        endRank,
                        playerCount,
                        gameType: GameType.SEVEN_CARD_STUD,
                      })
                    );
                  }
                  autoDeal(false);
                  setTabIndex(3);
                }}
              >
                7-CS
              </Tab>
              <Tab>
                <Icon
                  title="Open Settings"
                  as={FaCog}
                  onClick={() => {
                    autoDeal(false);
                    setTabIndex(4);
                  }}
                />
              </Tab>
              <Tab>
                <Icon
                  title="Instructions"
                  as={FaInfo}
                  onClick={() => {
                    autoDeal(false);
                    setTabIndex(5);
                  }}
                />
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
              <TabPanel>
                <Table variant="simple" size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Number of players:</Td>
                      <Td colSpan={2}>
                        <select tabIndex={0} ref={selectPlayersRef}>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </select>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Deck Range:</Td>
                      <Td>
                        From:
                        <br />
                        <select tabIndex={0} ref={rangeFromRef} onChange={handleResetDisabled} defaultValue={2}>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="T">T</option>
                          <option value="J">J</option>
                          <option value="Q">Q</option>
                          <option value="K">K</option>
                          <option value="A">A</option>
                        </select>
                        <br />
                      </Td>
                      <Td>
                        To: <br />
                        <select tabIndex={0} ref={rangeToRef} onChange={handleResetDisabled} defaultValue={"A"}>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="T">T</option>
                          <option value="J">J</option>
                          <option value="Q">Q</option>
                          <option value="K">K</option>
                          <option value="A">A</option>
                        </select>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Auto-deal Speed:</Td>
                      <Td colSpan={2}>
                        <select tabIndex={0} ref={autoDealSpeedRef} defaultValue={5000}>
                          <option value="3000">3 sec</option>
                          <option value="4000">4 sec</option>
                          <option value="5000">5 sec</option>
                          <option value="7000">7 sec</option>
                          <option value="10000">10 sec</option>
                        </select>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TabPanel>
              <TabPanel>
                <br />
                <Grid align="left">
                  <GridItem>- Select game by clicking the desired tab.</GridItem>
                  <GridItem>- Select cards by clicking them.</GridItem>
                  <GridItem>
                    - Choose the number of players, deck range, and auto-deal speed from the settings tab.
                  </GridItem>
                  <GridItem>- Choose dead cards from the bottom tray.</GridItem>

                  <br />
                  <GridItem>
                    <b>Accessibility options:</b>
                  </GridItem>
                  <GridItem>- Start a new hand = Press +</GridItem>
                  <GridItem>- Step back = Press Shift + ArrowLeft</GridItem>
                  <GridItem>- Step forward = Press Shift + ArrowRight</GridItem>
                  <GridItem>- Play/pause = Press P</GridItem>
                </Grid>
                <br />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Grid className="action-buttons" templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem>
              <button ref={btnResetRef} onClick={onReset}>
                <Icon as={FaPlus} title="New Hand" />
              </button>
            </GridItem>
            <GridItem>
              <button
                ref={btnArrowLeftRef}
                // disabled={currentRound === 0 ? true : false}
                onClick={() => {
                  dispatch(pokerStoreActions.clearAllPlayersOdds());
                  dispatch(pokerStoreActions.stepBack());
                  setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
                  currentRoundRef.current--;
                  autoDeal(false);
                }}
              >
                <Icon as={FaArrowLeft} title="Step Back" />
              </button>
            </GridItem>
            <GridItem colSpan={2}>
              <b>{getGameName()}</b>
            </GridItem>
            <GridItem>
              <button
                ref={btnArrowRightRef}
                onClick={() => {
                  dispatch(pokerStoreActions.clearAllPlayersOdds());
                  dispatch(pokerStoreActions.stepForward());
                  setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
                  currentRoundRef.current++;
                  autoDeal(false);
                }}
                disabled={deck?.cards.length === 0}
              >
                <Icon as={FaArrowRight} title="Step Forward" />
              </button>
            </GridItem>
            <GridItem>
              <button className="play" ref={btnPlayRef} onClick={onAutoDeal}>
                <Icon
                  as={autoDealingTimer ? FaPause : FaPlay}
                  title={autoDealingTimer ? "Stop Auto-deal" : "Start Auto-deal}"}
                />
              </button>
              {autoDealingTimer ? (
                <div class="circular-progress">
                  <CircularProgress
                    size={5}
                    thickness={15}
                    value={autoDealingProgressValue}
                    style={{ position: "relative", top: "-5px" }}
                  />
                </div>
              ) : null}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
}
