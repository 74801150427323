import React, { useEffect, useRef } from "react";

export default function Music() {
  const ref = useRef(null);
  useEffect(() => {
    // setTimeout(() => ref.current?.play(), 1000);
  }, []);

  return (
    <>
      {/* <iframe
        src="https://fluxfm.streamabc.net/flx-chillhop-mp3-128-8581707?sABC=66769r0q%230%23204893n194223n9rro9rp052n0sn187s%23fgernzf.syhksz.qr&aw_0_1st.playerid=streams.fluxfm.de&amsparams=playerid:streams.fluxfm.de;skey:1719049741"
        allow="autoplay"
      ></iframe> */}
      <audio ref={ref} controls autoplay>
        <source
          src="https://fluxfm.streamabc.net/flx-chillhop-mp3-128-8581707?sABC=66769r0q%230%23204893n194223n9rro9rp052n0sn187s%23fgernzf.syhksz.qr&aw_0_1st.playerid=streams.fluxfm.de&amsparams=playerid:streams.fluxfm.de;skey:1719049741"
          type="audio/mpeg"
        />
      </audio>

      <iframe
        style={{ borderRadius: "12px" }}
        src="https://open.spotify.com/embed/playlist/37i9dQZF1E4xwBxrmLZGX2?utm_source=generator"
        width="100%"
        height="152"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </>
  );
}
