import { configureStore } from "@reduxjs/toolkit";
import { reducer as pokerStoreReducer } from "./slices/pokerStoreSlice";
import { reducer as modalStoreReducer } from "./slices/modalStoreSlice";

export default configureStore({
  reducer: {
    pokerStore: pokerStoreReducer,
    modalStore: modalStoreReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
