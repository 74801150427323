import React from "react";
import classNames from "classnames";

const Card = ({ card, cardIndex, onClick, isBoardCard = false, isWinner = false }) => {
  const arr = ["card", { [`flop-${cardIndex}`]: isBoardCard, "anim-flop": isBoardCard, winner: isWinner }];
  const classes = classNames(arr);

  const url = `/static/images/small-deck/${card}.svg`;
  return (
    <input
      type="image"
      tabIndex={0}
      src={url}
      className={classes}
      alt={card}
      title={card}
      cardindex={cardIndex}
      onClick={onClick}
    />
  );
};

export default Card;
