"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Poker = void 0;
const Hand_1 = require("../Hand");
const types_1 = require("../lib/types");
const consts_1 = require("../lib/consts");
class Poker {
    MINIMUM_FLUSH_CARDS;
    INIT_CODE = "0.0.0";
    constructor(minimumFlushCards = 5) {
        this.MINIMUM_FLUSH_CARDS = minimumFlushCards;
    }
    /**
     * Returns a string contianing the following code:
     * hankRankNumber.[highCardRankNumber,secondCardRankNumber].suitNumber
     *
     * Example: 10.14.4 = RoyalFlush.Ace.Spades
     */
    evaluateStrength(hand) {
        const strengthFunctions = [
            this.checkStraightFlush,
            this.checkFourOfAKind,
            this.checkFullHouse,
            this.checkFlush,
            this.checkStraight,
            this.checkThreeOfAKind,
            this.checkTwoPair,
            this.checkOnePair,
            this.checkHighCard,
        ];
        for (const fn of strengthFunctions) {
            const code = fn.call(this, hand);
            if (code && code !== this.INIT_CODE) {
                const strengthCode = code;
                return strengthCode;
            }
        }
        return this.INIT_CODE;
    }
    getHandStrengthName(strengthCode) {
        strengthCode = strengthCode || "0.0.0";
        const [handStrengthNumber, ranksArr, suitNumber] = strengthCode.split(".");
        // const [highRank, secondRank]: TRankChar[] = ranksArr.split(",") as TRankChar[];
        let highRank = ranksArr.split(",")[0];
        let secondRank = ranksArr.split(",")[3]; // Full House, Two Pair
        const suitChar = this.getMapKeyByValue(consts_1.SuitsMap, parseInt(suitNumber));
        const suitName = consts_1.SuitNamesMap[suitChar];
        const highRankKey = this.getMapKeyByValue(consts_1.RankValuesMap, parseInt(highRank));
        const secondRankKey = this.getMapKeyByValue(consts_1.RankValuesMap, parseInt(secondRank));
        const highRankName = consts_1.RankNamesMap[highRankKey];
        const secondRankName = consts_1.RankNamesMap[secondRankKey];
        const map = {
            [types_1.EHandStrength.ROYAL_FLUSH]: `Royal Flush, ${suitName}`,
            [types_1.EHandStrength.STRAIGHT_FLUSH]: `${highRankName} High Straight Flush, ${suitName}`,
            [types_1.EHandStrength.FOUR_OF_A_KIND]: `Four of a Kind, ${highRankName}s`,
            [types_1.EHandStrength.FULL_HOUSE]: `Full House, ${highRankName}s and ${secondRankName}s`,
            [types_1.EHandStrength.FLUSH]: `${highRankName} High Flush, ${suitName}`,
            [types_1.EHandStrength.STRAIGHT]: `${highRankName} High Straight`,
            [types_1.EHandStrength.THREE_OF_A_KIND]: `Three of a Kind, ${highRankName}s`,
            [types_1.EHandStrength.TWO_PAIR]: `Two Pair, ${highRankName}s and ${secondRankName}s`,
            [types_1.EHandStrength.ONE_PAIR]: `One Pair, ${highRankName}s`,
            [types_1.EHandStrength.HIGH_CARD]: `${highRankName} High`,
        };
        const strength = parseInt(handStrengthNumber);
        return map[strength];
    }
    /**
     * [ranks] is an array.  For example for 2 pair there are 2 ranks.
     */
    generateHandStrengthCode(strength, ranks, flushSuit = undefined) {
        const suitNumber = flushSuit ? consts_1.SuitsMap[flushSuit] : "0";
        const strengthPadded = strength < types_1.EHandStrength.ROYAL_FLUSH ? "0" + strength : strength;
        const numericRanksArr = ranks.map((rank) => consts_1.RankValuesMap[rank]);
        const numericRanksArrPadded = numericRanksArr.map((num) => (num < 10 ? "0" + num : num));
        if (numericRanksArr.length === 0) {
            return this.INIT_CODE;
        }
        return `${strengthPadded}.${numericRanksArrPadded.join()}.${suitNumber}`;
    }
    getFlushSuit(cards) {
        const hand = (0, Hand_1.Hand)(cards);
        const suit = Object.keys(hand.suitsMap).find((suit) => hand.suitsMap[suit] >= this.MINIMUM_FLUSH_CARDS);
        return suit;
    }
    getMapKeyByValue(object, value) {
        for (const prop in object) {
            if (object.hasOwnProperty(prop)) {
                if (object[prop] === value) {
                    return prop;
                }
            }
        }
    }
    /**
     * Card example: Td
     */
    getFlushCards(cards, flushSuit) {
        const hand = (0, Hand_1.Hand)(cards);
        const filteredCards = hand.cards.filter(([rank, suit]) => suit === flushSuit);
        return filteredCards;
    }
    checkHighCard(cards) {
        const sortedCardsArr = [...cards].sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        // select top 5 rankgs
        const ranks = sortedCardsArr.slice(0, 5).map((card) => card.toString().charAt(0));
        // const card: TCard = sortedCardsArr[0];
        // const rank: TRankChar = card.charAt(0) as TRankChar;
        const code = this.generateHandStrengthCode(types_1.EHandStrength.HIGH_CARD, ranks);
        return code;
    }
    checkOnePair(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const pairsArr = Object.entries(hand.ranksMap).filter(([rank, count]) => count === 2);
        const remainder = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count < 2)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        // only one pair
        if (pairsArr.length === 1) {
            const cardEntry = pairsArr[0];
            const rankChar = cardEntry[0];
            const ranks = [rankChar, rankChar, ...remainder.slice(0, 3).map((card) => card.toString().charAt(0))];
            code = this.generateHandStrengthCode(types_1.EHandStrength.ONE_PAIR, ranks);
        }
        return code;
    }
    checkTwoPair(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const pairsArr = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count === 2)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        // take the first 2 high pairs
        const ranks = pairsArr.map(([rank, count]) => rank).slice(0, 2);
        const remainderArr = Object.entries(hand.ranksMap).filter(([rank, count]) => !ranks.includes(rank));
        // .sort(([rank1, suit1], [rank2, suit2]) => RankValuesMap[rank2] - RankValuesMap[rank1]);
        const set = remainderArr
            .map(([rank, count]) => rank)
            .sort((rank1, rank2) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        if (pairsArr.length === 2 || pairsArr.length === 3) {
            const highCardEntry = pairsArr[0];
            const secondCardEntry = pairsArr[1];
            // const remainderEntry: [string, number] = remainderArr[0];
            const highRank = highCardEntry[0];
            const secondRank = secondCardEntry[0];
            const remainder = set[0];
            const ranks = [highRank, highRank, secondRank, secondRank, remainder];
            code = this.generateHandStrengthCode(types_1.EHandStrength.TWO_PAIR, ranks);
        }
        return code;
    }
    /**
     * Only 3 of a kind with no additional pairs, thus not a full
     */
    checkThreeOfAKind(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const pairsArr = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count === 2)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        const tripsArr = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count === 3)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        const remainder = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count < 3)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        if (tripsArr.length === 1 && pairsArr.length === 0) {
            const cardEntry = tripsArr[0];
            const highRank = cardEntry[0];
            const ranks = [highRank, highRank, highRank, ...remainder.slice(0, 2).map((card) => card.toString().charAt(0))];
            code = this.generateHandStrengthCode(types_1.EHandStrength.THREE_OF_A_KIND, ranks);
        }
        return code;
    }
    checkStraight(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const numericRanks = Object.entries(hand.ranksMap).map(([rank, count]) => consts_1.RankValuesMap[rank]);
        const sortedUniqueNumericRanks = [...new Set(numericRanks)].sort((a, b) => b - a);
        // add { A: 1 } for low end of straight
        const ACE = 14;
        if (sortedUniqueNumericRanks.includes(ACE)) {
            sortedUniqueNumericRanks.push(1);
        }
        for (let i = 0; i < sortedUniqueNumericRanks.length - 4; i++) {
            // have straight
            if (sortedUniqueNumericRanks[i] - sortedUniqueNumericRanks[i + 4] === 4) {
                // const highRankChar: TRankChar = this.getMapKeyByValue<number>(
                //   RankValuesMap,
                //   sortedUniqueNumericRanks[i]
                // ) as TRankChar;
                const ranks = [];
                const highRankValue = sortedUniqueNumericRanks[i];
                for (let i = 0; i < 5; i++) {
                    let rankValue = highRankValue - i;
                    // low end ace
                    if (rankValue === 1) {
                        rankValue = consts_1.RankValuesMap["A"];
                    }
                    const highRankChar = this.getMapKeyByValue(consts_1.RankValuesMap, rankValue);
                    ranks.push(highRankChar);
                }
                code = this.generateHandStrengthCode(types_1.EHandStrength.STRAIGHT, ranks);
                break;
            }
        }
        return code;
    }
    checkFlush(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const suitCountArr = Object.entries(hand.suitsMap).find(([suit, count]) => count >= 5);
        // we have a flush
        if (suitCountArr) {
            const flushSuit = suitCountArr[0];
            // sort by descending rank
            const sortedCards = [...hand.cards].sort((card1, card2) => consts_1.RankValuesMap[card2.charAt(0)] - consts_1.RankValuesMap[card1.charAt(0)]);
            const sortedFlushCardsArr = sortedCards.filter((card) => card.charAt(1) === flushSuit);
            const highCard = sortedFlushCardsArr[0];
            const rank = highCard.charAt(0);
            const suit = highCard.charAt(1);
            const ranks = sortedFlushCardsArr.slice(0, 5).map((card) => card.charAt(0));
            code = this.generateHandStrengthCode(types_1.EHandStrength.FLUSH, ranks, suit);
        }
        return code;
    }
    checkFullHouse(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const pairsArr = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count === 2)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        const tripsArr = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count === 3)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        if (tripsArr.length === 2) {
            const highCardEntry = tripsArr[0];
            const secondCardEntry = tripsArr[1];
            const highRank = highCardEntry[0];
            const secondRank = secondCardEntry[0];
            const ranks = [highRank, highRank, highRank, secondRank, secondRank];
            code = this.generateHandStrengthCode(types_1.EHandStrength.FULL_HOUSE, ranks);
        }
        else if (tripsArr.length === 1 && (pairsArr.length === 1 || pairsArr.length === 2)) {
            const highCardEntry = tripsArr[0];
            const secondCardEntry = pairsArr[0];
            const highRank = highCardEntry[0];
            const secondRank = secondCardEntry[0];
            const ranks = [highRank, highRank, highRank, secondRank, secondRank];
            code = this.generateHandStrengthCode(types_1.EHandStrength.FULL_HOUSE, ranks);
        }
        return code;
    }
    checkFourOfAKind(cards) {
        let code = this.INIT_CODE;
        const hand = (0, Hand_1.Hand)(cards);
        const quadsArr = Object.entries(hand.ranksMap).find(([rank, count]) => count === 4);
        const remainder = Object.entries(hand.ranksMap)
            .filter(([rank, count]) => count < 4)
            .sort(([rank1, suit1], [rank2, suit2]) => consts_1.RankValuesMap[rank2] - consts_1.RankValuesMap[rank1]);
        if (quadsArr) {
            const rank = quadsArr[0];
            const entry = remainder[0];
            const ranks = [rank, rank, rank, rank, entry[0]];
            code = this.generateHandStrengthCode(types_1.EHandStrength.FOUR_OF_A_KIND, ranks);
        }
        return code;
    }
    checkStraightFlush(cards) {
        let code = this.INIT_CODE;
        const flushSuit = this.getFlushSuit(cards);
        if (!flushSuit) {
            return this.INIT_CODE;
        }
        const flushCards = this.getFlushCards(cards, flushSuit);
        const sortedNumericRanks = flushCards.map(([rank, suit]) => consts_1.RankValuesMap[rank]);
        // add { A: 1 } for low end of straight
        const ACE = 14;
        if (sortedNumericRanks.includes(ACE)) {
            sortedNumericRanks.push(1);
        }
        sortedNumericRanks.sort((a, b) => b - a);
        for (let i = 0; i < sortedNumericRanks.length - 4; i++) {
            // have straight
            if (sortedNumericRanks[i] - sortedNumericRanks[i + 4] === 4) {
                const highRank = sortedNumericRanks[i];
                // const highRankChar: TRankChar = this.getMapKeyByValue<number>(RankValuesMap, highRank) as TRankChar;
                const ranks = [];
                const highRankValue = sortedNumericRanks[i];
                for (let i = 0; i < 5; i++) {
                    let rankValue = highRankValue - i;
                    // low end ace
                    if (rankValue === 1) {
                        rankValue = consts_1.RankValuesMap["A"];
                    }
                    const highRankChar = this.getMapKeyByValue(consts_1.RankValuesMap, rankValue);
                    ranks.push(highRankChar);
                }
                code = this.generateHandStrengthCode(highRank === ACE ? types_1.EHandStrength.ROYAL_FLUSH : types_1.EHandStrength.STRAIGHT_FLUSH, ranks, flushSuit);
                break;
            }
        }
        return code;
    }
}
exports.Poker = Poker;
