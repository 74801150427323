"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonteCarlo_SevenCardStud = exports.MonteCarlo_FiveCardDraw = exports.MonteCarlo_Omaha = exports.MonteCarlo_TexasHoldem = void 0;
const Deck_1 = require("../Deck");
const Poker_1 = require("./Poker");
const types_1 = require("../lib/types");
class MonteCarloApproximation {
    SIMULATION_COUNT;
    PLAYER_CARD_COUNT;
    BOARD_CARD_COUNT;
    name;
    constructor(simulationCount) {
        this.SIMULATION_COUNT = simulationCount;
        this.name = null;
        this.PLAYER_CARD_COUNT = 0;
        this.BOARD_CARD_COUNT = 0;
    }
    // simulate(players: TCard[][], board: TCard[] = []) {
    simulate({ players, board = [], deadCards = [] }) {
        // const result = [
        //     { win: 0.81, tie: 0.03}...
        // ]
        const result = [];
        for (let player = 0; player < players.length; player++) {
            result[player] = { win: 0, tie: 0 };
        }
        // run simulations
        for (let sim = 0; sim < this.SIMULATION_COUNT; sim++) {
            const thePlayers = [...players];
            const theBoard = [...board];
            const theDeadCards = [...deadCards];
            this.oneSimulation({ players: thePlayers, board: theBoard, deadCards: theDeadCards, result });
        }
        // convert to %
        for (let player = 0; player < players.length; player++) {
            result[player].tie = +(result[player].tie / this.SIMULATION_COUNT).toFixed(3);
            result[player].win = +(result[player].win / this.SIMULATION_COUNT).toFixed(3);
        }
        return result;
    }
    oneSimulation({ players, board = [], deadCards = [], result = [] }) {
        // const result: any[] = [];
        const poker = new Poker_1.Poker();
        let hands = [];
        const deck = new Deck_1.Deck();
        deck.shuffle();
        // remove cards from the deck
        players.forEach((cards) => {
            cards.forEach((card) => deck.removeFromDeck(card));
        });
        board.forEach((card) => {
            deck.removeFromDeck(card);
        });
        deadCards.forEach((card) => {
            deck.removeFromDeck(card);
        });
        deck.shuffle();
        // create full hand, holdem
        switch (this.name) {
            case types_1.GameType.TEXAS_HOLDEM:
                {
                    // add any missing player cards
                    while (board.length < this.BOARD_CARD_COUNT) {
                        const card = deck.draw(1)[0];
                        board.push(card);
                    }
                    players.forEach(([...cards]) => {
                        // add any missing player cards
                        while (cards.length < this.PLAYER_CARD_COUNT) {
                            const card = deck.draw(1)[0];
                            cards.push(card);
                        }
                        const hand = [...cards, ...board];
                        hands.push(hand);
                    });
                }
                break;
            case types_1.GameType.FIVE_CARD_DRAW:
            case types_1.GameType.SEVEN_CARD_STUD:
                players.forEach(([...cards]) => {
                    // add any missing player cards
                    while (cards.length < this.PLAYER_CARD_COUNT) {
                        const card = deck.draw(1)[0];
                        cards.push(card);
                    }
                    const hand = [...cards];
                    hands.push(hand);
                });
                break;
            case types_1.GameType.OMAHA:
                {
                    // add any missing player cards
                    while (board.length < this.BOARD_CARD_COUNT) {
                        const card = deck.draw(1)[0];
                        board.push(card);
                    }
                    players.forEach((cards) => {
                        // add any missing player cards
                        while (cards.length < this.PLAYER_CARD_COUNT) {
                            const card = deck.draw(1)[0];
                            cards.push(card);
                        }
                        // get the best hand out of all combos
                        let hand = [];
                        const playerCombos = this.combinations(cards, 2);
                        const boardCombos = this.combinations(board, 3);
                        let strength = "0.0.0";
                        playerCombos.forEach((playerCombo) => {
                            boardCombos.forEach((boardCombo) => {
                                const currentHand = [...playerCombo, ...boardCombo];
                                const strengthCode = poker.evaluateStrength(currentHand);
                                if (strengthCode > strength) {
                                    strength = strengthCode;
                                    hand = currentHand;
                                }
                            });
                        });
                        hands.push(hand);
                    });
                }
                break;
            default:
                throw new Error("[game] not set properly.");
        }
        // get strengths
        const playerStrengthArr = [];
        hands.forEach((hand) => {
            const strength = poker.evaluateStrength(hand);
            playerStrengthArr.push(strength);
            playerStrengthArr.sort((a, b) => (b > a ? 1 : 0));
        });
        // create results
        let highValue = playerStrengthArr[0];
        for (let i = 1; i < playerStrengthArr.length; i++) {
            if (playerStrengthArr[i] > highValue) {
                highValue = playerStrengthArr[i];
            }
        }
        const isTie = playerStrengthArr.filter((strength) => strength === highValue).length > 1;
        for (let i = 0; i < playerStrengthArr.length; i++) {
            result[i] ||= { win: 0, tie: 0 };
            if (playerStrengthArr[i] === highValue) {
                if (isTie) {
                    result[i].tie++;
                }
                else {
                    result[i].win++;
                }
            }
        }
        // return result;
    }
    combinations(cards, k) {
        let result = [];
        let combination = [];
        function combine(start, depth) {
            if (depth === k) {
                result.push([...combination]);
                return;
            }
            for (let i = start; i < cards.length; i++) {
                combination.push(cards[i]);
                combine(i + 1, depth + 1);
                combination.pop();
            }
        }
        combine(0, 0);
        return result;
    }
}
class MonteCarlo_TexasHoldem extends MonteCarloApproximation {
    constructor(simulationCount) {
        super(simulationCount);
        this.PLAYER_CARD_COUNT = 2;
        this.BOARD_CARD_COUNT = 5;
        this.name = types_1.GameType.TEXAS_HOLDEM;
    }
}
exports.MonteCarlo_TexasHoldem = MonteCarlo_TexasHoldem;
class MonteCarlo_FiveCardDraw extends MonteCarloApproximation {
    constructor(simulationCount) {
        super(simulationCount);
        this.PLAYER_CARD_COUNT = 5;
        this.BOARD_CARD_COUNT = 0;
        this.name = types_1.GameType.FIVE_CARD_DRAW;
    }
}
exports.MonteCarlo_FiveCardDraw = MonteCarlo_FiveCardDraw;
class MonteCarlo_SevenCardStud extends MonteCarloApproximation {
    constructor(simulationCount) {
        super(simulationCount);
        this.PLAYER_CARD_COUNT = 7;
        this.BOARD_CARD_COUNT = 0;
        this.name = types_1.GameType.SEVEN_CARD_STUD;
    }
}
exports.MonteCarlo_SevenCardStud = MonteCarlo_SevenCardStud;
class MonteCarlo_Omaha extends MonteCarloApproximation {
    constructor(simulationCount) {
        super(simulationCount);
        this.PLAYER_CARD_COUNT = 4;
        this.BOARD_CARD_COUNT = 5;
        this.name = types_1.GameType.OMAHA;
    }
}
exports.MonteCarlo_Omaha = MonteCarlo_Omaha;
