import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import classNames from "classnames";
import { GameType } from "poker-hand-strength-calculator";

import { actions as modalStoreActions } from "../store/slices/modalStoreSlice";
import { actions as pokerStoreActions } from "../store/slices/pokerStoreSlice";

import React from "react";
import Card from "./Card";
import { CALCULATE_ODDS_DELAY, ERounds } from "../lib/consts";

export default function Player({ playerIndex, player, coords }) {
  const dispatch = useDispatch();
  const { currentRound, game } = useSelector((state) => state.pokerStore);

  const allClassNames = classNames("player", game.name === GameType.SEVEN_CARD_STUD ? "SevenCardStud" : null);

  return (
    <>
      <div className={allClassNames} style={{ position: "absolute", top: coords?.y, left: coords?.x }}>
        {player.cards.map(({ card, winner }, cardIndex) => (
          <Card
            key={cardIndex}
            card={card}
            cardIndex={cardIndex}
            isWinner={winner}
            onClick={() => {
              dispatch(modalStoreActions.openModal({ type: "player", playerIndex, cardIndex }));
              dispatch(pokerStoreActions.resetPlayerCard({ card, playerIndex, cardIndex }));
            }}
          />
        ))}

        <div className="description" tabIndex={0}>
          {player.handStrengthName ? (
            <>
              <span style={{ color: "yellow" }}>
                <b>{player.handStrengthName}</b>
              </span>
              <br />
            </>
          ) : null}

          <span style={{ color: "white " }}>{player.name}</span>

          {currentRound === ERounds.GAME_START ? null : typeof player.winPercentage !== "number" ? (
            <>
              &nbsp; &nbsp; &nbsp;
              <span style={{ color: "lime", fontWeight: "bold", fontSize: "16px" }}>
                <Spinner emptyColor="gray.200" size="sm" />
              </span>
            </>
          ) : (
            <>
              &nbsp; &nbsp; &nbsp;
              <span style={{ color: "lime", fontWeight: "bold", fontSize: "16px" }}>
                {(player.winPercentage * 100).toFixed(0) + "%"}
              </span>
            </>
          )}

          {player.tiePercentage ? (
            <>
              &nbsp; &nbsp;
              <span style={{ color: "red", fontWeight: "bold" }}>{(player.tiePercentage * 100).toFixed(0) + "%"}</span>
            </>
          ) : null}
          {/* <div>
            <button
              className="fold"
              onClick={() => {
                dispatch(pokerStoreActions.foldPlayer({ playerIndex }));
                dispatch(pokerStoreActions.clearAllPlayersOdds());
                setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
              }}
            >
              Fold
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}
