import { useEffect } from "react";

export function useKeyPress(callbackMap) {
  const handler = (e) => {
    // disable if on chakra tabs
    if (document.activeElement.closest(".chakra-tabs")) {
      return false;
    }

    const callback = callbackMap[e.key];
    switch (e.key) {
      case "ArrowLeft":
      case "ArrowRight":
        if (e.shiftKey) {
          callback(e);
        }
        break;
      case "+":
      case " ":
      default:
        callback?.(e);
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, []);
}

export const SPACE = 32;
export const ARROW_LEFT = 37;
export const ARROW_RIGHT = 39;
export const PLUS = 107;
