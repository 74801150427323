import React from "react";

import { SimpleGrid, Box } from "@chakra-ui/react";

export default function About() {
  return (
    <SimpleGrid background="white.100" style={{ display: "grid", justifyContent: "left" }}>
      <Box>
        <h1>About PokerSimulator.app</h1>
        <p>
          Welcome to PokerSimulator.app, your ultimate destination for mastering the art of poker! Whether you're a
          seasoned pro or just starting out, our platform offers a comprehensive and immersive poker simulation
          experience designed to enhance your skills, strategy, and overall gameplay.
        </p>
        <h3>Our Mission</h3>
        <p>
          At PokerSimulator.app, our mission is to provide a realistic and engaging poker environment where players of
          all levels can practice, learn, and refine their strategies. We believe that the key to becoming a great poker
          player lies in continuous practice and a deep understanding of the game's intricacies. Our platform is
          dedicated to helping you achieve that by offering a wide range of features and tools tailored to improve your
          poker prowess.
        </p>
        <h3>What We Offer</h3>
        <ul>
          <li>
            <p>
              <strong>Realistic Simulations</strong>: Experience the thrill of poker with our highly realistic
              simulations that mimic real-life gameplay. Our advanced algorithms ensure that each game feels authentic
              and challenging.
            </p>
          </li>
          <li>
            <p>
              <strong>Skill Development</strong>: Improve your skills with our interactive tutorials, strategy guides,
              and practice modes. Whether you need to learn the basics or advanced tactics, we've got you covered.
            </p>
          </li>
          <li>
            <p>
              <strong>Customizable Scenarios</strong>: Create and customize your own poker scenarios to practice
              specific situations and strategies. Tailor the game to your needs and focus on areas where you want to
              improve.
            </p>
          </li>
          <li>
            <p>
              <strong>Community and Support</strong>: Join our vibrant community of poker enthusiasts, share insights,
              and learn from others. Our support team is always here to help you with any questions or issues you might
              encounter.
            </p>
          </li>
        </ul>
        <h3>Why Choose PokerSimulator.app?</h3>
        <ul>
          <li>
            <p>
              <strong>User-Friendly Interface</strong>: Our platform is designed with simplicity and ease of use in
              mind, ensuring that you can focus on the game without any distractions.
            </p>
          </li>
          <li>
            <p>
              <strong>Continuous Updates</strong>: We are committed to constantly improving and updating our platform
              based on user feedback and the latest trends in poker strategy.
            </p>
          </li>
          <li>
            <p>
              <strong>Accessible Anywhere</strong>: Play and practice poker anytime, anywhere. Our web-based platform
              ensures that you can access your account and continue your journey to poker mastery on any device.
            </p>
          </li>
        </ul>
        <h3>Join Us Today!</h3>
        <p>
          Start your journey to becoming a poker champion with PokerSimulator.app. Sign up today and take the first step
          towards mastering the game!
        </p>
        <hr />
        <p>Feel free to modify any sections to better fit the tone and specifics of your website.</p>
      </Box>
    </SimpleGrid>
  );
}
