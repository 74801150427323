import React from "react";

import { SimpleGrid, Box } from "@chakra-ui/react";

export default function Footer() {
  return (
    <>
      <SimpleGrid columns={4} className="footer">
        <Box>
          <a href="/">Home</a>
        </Box>
        <Box>
          <a href="/privacy">Privacy Policy</a>
        </Box>
        <Box>
          <a href="/terms">Terms of Use</a>
        </Box>
        <Box>
          <a href="/about">About</a>
        </Box>
      </SimpleGrid>
    </>
  );
}
