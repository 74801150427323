export const ERounds = {
  GAME_START: 0,
};

export const MAX_MOBILE_VIEW_PORT = 959;

export const CALCULATE_ODDS_DELAY = 500;

const RankValuesMap = {};
RankValuesMap[(RankValuesMap["2"] = 2)] = "2";
RankValuesMap[(RankValuesMap["3"] = 3)] = "3";
RankValuesMap[(RankValuesMap["4"] = 4)] = "4";
RankValuesMap[(RankValuesMap["5"] = 5)] = "5";
RankValuesMap[(RankValuesMap["6"] = 6)] = "6";
RankValuesMap[(RankValuesMap["7"] = 7)] = "7";
RankValuesMap[(RankValuesMap["8"] = 8)] = "8";
RankValuesMap[(RankValuesMap["9"] = 9)] = "9";
RankValuesMap[(RankValuesMap["T"] = 10)] = "T";
RankValuesMap[(RankValuesMap["J"] = 11)] = "J";
RankValuesMap[(RankValuesMap["Q"] = 12)] = "Q";
RankValuesMap[(RankValuesMap["K"] = 13)] = "K";
RankValuesMap[(RankValuesMap["A"] = 14)] = "A";
export { RankValuesMap };
