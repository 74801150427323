import React from "react";
// import Modal from "react-modal";

import { Deck } from "poker-hand-strength-calculator";
import { SimpleGrid } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import { actions as modalStoreActions } from "../store/slices/modalStoreSlice";
import { actions as pokerStoreActions } from "../store/slices/pokerStoreSlice";
import { CALCULATE_ODDS_DELAY } from "../lib/consts";

function SelectCardModal() {
  const dispatch = useDispatch();
  const { deck } = useSelector((state) => state.pokerStore);
  const { isModalOpen, cardIndex, playerIndex, type } = useSelector((state) => state.modalStore);
  const { isMobileViewPort } = useSelector((state) => state.pokerStore);

  function getAvailableDeck() {
    const availableCards = new Deck().cards.reverse();
    for (let i = 0; i < availableCards.length; i++) {
      for (let j = 0; j < deck?.cards.length; j++) {
        const card = availableCards[i];
        if (!deck.cards.includes(card)) {
          availableCards[i] = "Xx";
        }
      }
    }
    return availableCards;
  }

  const onClick = ({ rank, suit }) => {
    switch (type) {
      case "board":
        dispatch(pokerStoreActions.setBoardCard({ card: `${rank}${suit}`, cardIndex }));
        dispatch(modalStoreActions.closeModal());
        setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
        break;
      case "player":
        dispatch(pokerStoreActions.setPlayerCard({ card: `${rank}${suit}`, playerIndex, cardIndex }));
        dispatch(modalStoreActions.closeModal());
        setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
        break;
      case "deadCards":
        dispatch(pokerStoreActions.setDeadCard({ card: `${rank}${suit}`, cardIndex }));
        dispatch(modalStoreActions.closeModal());
        setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      size={isMobileViewPort ? "md" : "2xl"}
      isCentered={true}
      onClose={() => {
        dispatch(modalStoreActions.closeModal());
        setTimeout(() => dispatch(pokerStoreActions.calculateOdds()), CALCULATE_ODDS_DELAY);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
          <br />
        </ModalHeader>
        <ModalBody>
          <SimpleGrid columns={[6, 13, 13]}>
            {getAvailableDeck().map(([rank, suit], index) => {
              return (
                <input
                  type="image"
                  tabIndex={0}
                  key={index}
                  src={`/static/images/small-deck/${rank}${suit}.svg`}
                  alt={`${rank}${suit}`}
                  onClick={() => onClick({ rank, suit })}
                />
              );
            })}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SelectCardModal;
