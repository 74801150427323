"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameType = exports.EHandStrength = void 0;
var EHandStrength;
(function (EHandStrength) {
    EHandStrength[EHandStrength["HIGH_CARD"] = 1] = "HIGH_CARD";
    EHandStrength[EHandStrength["ONE_PAIR"] = 2] = "ONE_PAIR";
    EHandStrength[EHandStrength["TWO_PAIR"] = 3] = "TWO_PAIR";
    EHandStrength[EHandStrength["THREE_OF_A_KIND"] = 4] = "THREE_OF_A_KIND";
    EHandStrength[EHandStrength["STRAIGHT"] = 5] = "STRAIGHT";
    EHandStrength[EHandStrength["FLUSH"] = 6] = "FLUSH";
    EHandStrength[EHandStrength["FULL_HOUSE"] = 7] = "FULL_HOUSE";
    EHandStrength[EHandStrength["FOUR_OF_A_KIND"] = 8] = "FOUR_OF_A_KIND";
    EHandStrength[EHandStrength["STRAIGHT_FLUSH"] = 9] = "STRAIGHT_FLUSH";
    EHandStrength[EHandStrength["ROYAL_FLUSH"] = 10] = "ROYAL_FLUSH";
})(EHandStrength || (exports.EHandStrength = EHandStrength = {}));
var GameType;
(function (GameType) {
    GameType[GameType["TEXAS_HOLDEM"] = 1] = "TEXAS_HOLDEM";
    GameType[GameType["OMAHA"] = 2] = "OMAHA";
    GameType[GameType["FIVE_CARD_DRAW"] = 3] = "FIVE_CARD_DRAW";
    GameType[GameType["SEVEN_CARD_STUD"] = 4] = "SEVEN_CARD_STUD";
})(GameType || (exports.GameType = GameType = {}));
