import React from "react";

import Felt from "./Felt";

export default function Home() {
  return (
    <>
      <Felt />
    </>
  );
}
