import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "./Card";

import { actions as modalStoreActions } from "../store/slices/modalStoreSlice";
import { actions as pokerStoreActions } from "../store/slices/pokerStoreSlice";

export default function DeadCards() {
  const dispatch = useDispatch();
  const { deadCards } = useSelector((state) => state.pokerStore);

  return (
    <>
      <div className="dead-cards">
        <b>Dead Cards</b> &nbsp;
        {deadCards.map((card, cardIndex) => {
          return (
            <Card
              key={cardIndex}
              cardIndex={cardIndex}
              card={card}
              isBoardCard={false}
              onClick={() => {
                dispatch(modalStoreActions.openModal({ type: "deadCards", cardIndex }));
                dispatch(pokerStoreActions.resetDeadCard({ card, cardIndex }));
              }}
            />
          );
        })}
      </div>
    </>
  );
}
