"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RankNamesMap = exports.RankValuesMap = exports.SuitNamesMap = exports.SuitsMap = void 0;
exports.SuitsMap = {
    c: 1,
    d: 2,
    h: 3,
    s: 4,
};
exports.SuitNamesMap = {
    c: "Clubs",
    d: "Diamonds",
    h: "Hearts",
    s: "Spades",
};
exports.RankValuesMap = {
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    T: 10,
    J: 11,
    Q: 12,
    K: 13,
    A: 14,
};
exports.RankNamesMap = {
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    T: "Ten",
    J: "Jack",
    Q: "Queen",
    K: "King",
    A: "Ace",
};
